import mixpanel from 'mixpanel-browser'

/**
 * @example
 * {
 *   title: 'Swap - El Dorado',
 *   fullPath: '/swap?swappers=MayaProtocol,ThorChain',
 * }
 */
export interface SwapPageProperties {
  title: string
  fullPath: string
}
/**
 * @example
 * {
 *   wallet: 'xdefi',
 * }
 * @example
 * {
 *   wallet: 'keystore',
 *   type: 'new'
 * }
 */
export interface WalletConnectedEventProperties {
  wallet: 'xdefi' | 'phantom' | 'talisman' | 'keystore'
  type?: 'new' | 'recovered'
}
/**
 * @prop amount - a stringified integer, to format the amount, in case of CACAO, divide by 1e10
 * @prop quoteAmount - a stringified integer, to format quoteAmount, in case of RUNE, divide by 1e8
 * @example
 * {
 *   amount: '10000000000', // equal to 1 after formatting
 *   fromChain: 'MAYA',
 *   fromAsset: 'CACAO',
 *   toChain: 'THOR',
 *   toAsset: 'RUNE',
 *   quoteAmount: '18514556', // equal to 0.18514556 after formatting
 * }
 */
export interface SwapSigningEventProperties {
  amount: string
  fromChain: string
  fromAsset: string
  toChain: string
  toAsset: string
  quoteAmount: string
}
/**
 * @reference SwapSigningEventProperties
 */
export interface SwapSignedEventProperties {
  amount: string
  fromChain: string
  fromAsset: string
  toChain: string
  toAsset: string
  quoteAmount: string
}
/**
 * @example
 * {
 *   status: 'success',
 *   outAmount: '18604577',
 *   amount: '1000000000',
 *   fromChain: 'MAYA',
 *   fromAsset: 'CACAO',
 *   toChain: 'THOR',
 *   toAsset: 'RUNE',
 * }
 */
export type SwapStatusEventProperties =
  | {
      status: string
      outAmount: string
      amount: string
      fromChain: string
      fromAsset: string
      toChain: string
      toAsset: string
    }
  | {
      status: string
      outAmount: string
      error?: string
    }

export const useAnalytics = () => {
  const runtimeConfig = useRuntimeConfig()
  const analyticsApiKey = runtimeConfig.public.ANALYTICS_API_KEY
  if (analyticsApiKey) {
    mixpanel.init(analyticsApiKey, {
      debug: runtimeConfig.public.NODE_ENV === 'development',
      track_pageview: true,
      persistence: 'localStorage',
    })
  }
  return mixpanel
}
